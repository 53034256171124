<div class=" alert unverified-email-box rounded-md bg-yellow-50 p-4">
    <button type="button" class="close text-yellow-700 hover:text-yellow-800" data-dismiss="alert" aria-label="Close">
        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            ></path>
        </svg>
    </button>
    <div class="flex items-start">
        <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                    fill-rule="evenodd"
                    d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                ></path>
            </svg>
        </div>
        <div class="ml-3">
            <h3 class="text-base font-medium text-yellow-800">Warning!</h3>
            <div class="mb-0 mt-2 text-sm text-yellow-700">
                <p class="m-0 text-sm text-yellow-700">
                    You have not confirmed your email address yet. Click on
                    <a
                        class="text-bold resend-verification-btn text-yellow-700 underline hover:text-yellow-800 hover:underline"
                        href="javascript:void(0)"
                        onClick="sendEmailConfirmation()"
                    >
                        this link</a
                    >
                    to resend the confirmation email.
                </p>
            </div>
        </div>
    </div>
</div>
